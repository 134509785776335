@use '@angular/material' as mat;
@import "colors";
@import "palette";
// Plus imports for other components in your app.

// always include only once per project
@include mat.core();
@font-face {
  font-family: gilroySemiBold;
  src: url(../../assets/fonts/Gilroy-SemiBold.otf) format("opentype");
}

@font-face {
  font-family: gilroy;
  src: url(../../assets/fonts/gilroy-light.otf) format("opentype");
}

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$caple-primary: mat.define-palette($palette-oxygen-purple);
$caple-accent:  mat.define-palette($palette-oxygen-yellow);

// The warn palette is optional (defaults to red).
$caple-warn:    mat.define-palette($palette-oxygen-dark-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$oxygen-theme: mat.define-light-theme((
  color: (
    primary: $caple-primary,
    accent: $caple-accent,
    warn: $caple-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($oxygen-theme);
