$oxygen-inactive-light-blue: #F7F9F6;
$oxygen-blue-light: #E6EBE0;
$oxygen-grey: #F7F7F7;
$oxygen-dark-grey: #00000099;
$oxygen-disabled-grey: #B6B6B6;
$oxygen-blue: #4440E2;
$oxygen-blue-shade: #00BAFF;
$oxygen-yellow: #FFCA38;
$oxygen-pink: #FF45E6;
$oxygen-red: #F73054;
$oxygen-dark-red: #A1483D;
$oxygen-dark-blue: #452674;
$oxygen-dark: #1C2B35;
$oxygen-purple: #5C329A;
$oxygen-green: #63C472;
$oxygen-black-text: #263A46;
$oxygen-beige: #E6E9EB;
$oxygen-transparent: rgb(255, 255, 255, 0);
