@import "assets/scss/caple.theme";
@import "assets/scss/colors";
/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: oxygen;
  src: url(./assets/fonts/Oxygen-Regular.ttf) format("opentype");
  font-display: block;
}

/*
@font-face was configured wrong, so we didn't use the actual bold version of the font, the browser changed the
look of the regular one.
This option is not working in Safari, so there the regular font was used.
Fixing the problem would change the appearance of the site on every browser. This hack only enables the bold font
version on Safari.
 */
@media not all and (min-resolution: .001dpcm) {
  @supports (-webkit-appearance:none) {
    @font-face {
      font-family: oxygen;
      font-weight: bold;
      src: url(./assets/fonts/Oxygen-Bold.ttf) format("opentype");
      font-display: block;
    }
  }
}

@font-face {
  font-family: oxygen-bold;
  src: url(./assets/fonts/Oxygen-Bold.ttf) format("opentype");
  font-display: block;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: oxygen, sans-serif !important;

  &.loading {
    cursor: wait !important;

    & .mat-flat-button {
      cursor: wait !important;
    }

    & .mat-checkbox {
      cursor: wait !important;
    }

    & .mat-tab-label {
      cursor: wait !important;
    }

    & p {
      cursor: wait !important;
    }
  }
}

.hideOnMobile {
  @media(max-width: 959px) {
    visibility: hidden;
  }
}

.scaleForMobile {
  @media(max-width: 959px) {
    transform: scale(0.8);
    transform-origin: 0 0;
    width: 125%;
    height: 125%;
  }

  @media(max-width: 799px) {
    transform: scale(0.7);
    width: 142.857142857%;
    height: 142.857142857%;
  }

  @media(max-width: 699px) {
    transform: scale(0.6);
    width: 166.666666667%;
    height: 166.666666667%;
  }

  @media(max-width: 599px) {
    transform: scale(0.5);
    width: 200%;
    height: 200%;
  }

  @media(max-width: 499px) {
    transform: scale(0.4);
    width: 250%;
    height: 250%;
  }
}

.boxShadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 1);
}

.emptyButton {
  height: 50px;
  color: #000000;
  border: 1px solid $oxygen-dark !important;
  border-radius: 5px !important;
  padding: 0 40px !important;
}

.countrySelect {
  margin: 0 3% 8px 0;
}

.eevery-big-btn {
  padding: 3px 15px !important;
  color: #FFFFFF;
  border-radius: 8px !important;

  &-220 {
    @extend .eevery-big-btn;
    min-width: 220px !important;
  }

  &-190 {
    @extend .eevery-big-btn;
    min-width: 190px !important;
  }

  &-160 {
    @extend .eevery-big-btn;
    min-width: 160px !important;
  }

  &-120 {
    @extend .eevery-big-btn;
    min-width: 120px !important;
  }
}

.eevery-round-btn {
  min-width: 160px !important;
  padding: 3px 10px !important;
  display: inline-block;
  border-radius: 100px !important;
  height: 42px;
  font-family: Oxygen, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px !important;
  align-items: center;
  text-align: center;

  &-outline {
    @extend .eevery-round-btn;
    color: $oxygen-dark;
    border: 2px solid $oxygen-dark !important;
  }

  &-yellow {
    @extend .eevery-round-btn;
    background: $oxygen-yellow;
    color: #000000;
  }

  &-transparent {
    @extend .eevery-round-btn;
    background: transparent;
    color: white;
    border: 2px solid white !important;
  }
}

app-onboarding,
app-sidenav,
app-accountant-questionnaire,
app-questionaire,
app-assessment-list,
app-profile,
app-accountant-clients,
app-accountant-scoring-requests,
app-accountant-onboarding,
app-accountant-dashboard,
app-accountant-questionnaire,
app-feedback {
  min-height: 100%;
}

.bottomButtons {
  margin: 10% 0 20px 0;
}

.bottom-buttons {
  margin: 40px 0 25px 0;
}

.outlined-button {
  color: $oxygen-blue;
  border: 1px solid $oxygen-blue !important;
}

.panel {
  overflow-x: hidden !important;
  background: #E6EBE0;
  position: relative;

  min-width: 60vw !important;
  transform: none !important;
}

/* width */
.panel::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.panel::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(92, 164, 169, 0.6);
  border-radius: 10px;
}

/* Handle */
.panel::-webkit-scrollbar-thumb {
  width: 6px;
  background: $oxygen-blue;
  border-radius: 10px;
}

/* Handle on hover */
.panel::-webkit-scrollbar-thumb:hover {
  background: $oxygen-dark;
}

.payrollQuestion .questiononlycontainer {
  & h4 {
    line-height: 12px;
    font-size: 12px;
    margin: 0 0 6px;
  }

  & p {
    display: none;
  }

  & .emptyButton {
    padding: 0 25px !important;
    font-size: 12px;
    height: 40px;
  }
}

.question-only {
  font-size: 24px !important;
  font-weight: bold !important;
  line-height: 28px !important;
}

.eevery-input {
  min-width: 280px;
  width: 30%;
}

.panelcolor .mat-expansion-panel-body {
  padding: 0 24px !important;
  background: #FFFFFF;
}

.mat_panel .mat-expansion-panel-body {
  padding: 0 0 0 30px;
}

.border-top {
  border-top: 1px solid $oxygen-blue;
}

.mat-dialog-container {
  border-radius: unset !important;
  border-top: 4px solid #4440E2;
  max-height: 100vh !important;
  scrollbar-color: $oxygen-blue $oxygen-transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(0, 0, 0, 0.3,);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $oxygen-blue;
    border-radius: 2px;
  }
}

.mat-tab-list {
  background: $oxygen-inactive-light-blue;
  padding: 0 0 0 10%;
  border-bottom: solid #e9eced 1px;
}

.mat-tab-label {
  min-width: 200px !important;
  margin: 0 40px 0 0;
  border: 1px solid grey;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
}

.mat-tab-label.mat-tab-label-active {
  border-bottom: none;
  background: white;
  opacity: 1;
  color: $oxygen-blue;
}

.remove-border-bottom .mat-tab-header {
  border-bottom: none;
}

mat-ink-bar {
  top: 0;
  border-radius: 5px 5px 0 0 !important;
  height: 4px !important;
}

.mat-tab-nav-bar, .mat-tab-header {
  border-bottom: none;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background: $oxygen-grey;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-checkbox-inner-container {
  margin-top: 4px;
}

.comment .mat-form-field-infix {
  border-top: unset;
}

.mat-form-field-prefix {
  margin-right: 5px;
}

mat-tab-group {
  .mat-tab-header {
    .mat-tab-header-pagination {
      display: none !important; // <== disable pagination
    }

    .mat-tab-label-container {
      left: 0; // if you need to use it on mobile - set left position to 0
      width: 100%;

      .mat-tab-list {
        overflow-x: auto !important; // <== set horisontal scroll bar imperatively
        scrollbar-color: $oxygen-blue $oxygen-transparent;
        scrollbar-width: none;
        // below rule prevents sliding of buttons' container - because it not sliding properly - to left it not slide as well
        transform: none !important;
      }
    }
  }
}

.mat-tab-list::-webkit-scrollbar {
  display: none !important;
  height: 0.5em;
}

.mat-tab-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(0, 0, 0, 0.3,);
}

.mat-tab-list::-webkit-scrollbar-thumb {
  background-color: $oxygen-blue;
  border-radius: 2px;
}

.mat-progress-bar-buffer {
  background-color: #E6E9EB;
}

.eesg_indicator .mat-expansion-indicator span {
  color: $oxygen-blue;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0;
  padding: 0;
}

.help_checkbox .mat-checkbox-layout .mat-checkbox-label {
  line-height: 37px;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  z-index: 2;
  padding: 0!important;
}

.backbutton {
   .mat-button-focus-overlay {
     background: transparent!important;
   }
}

@media only screen and (max-width: 959px) {
  .mat-tab-list {
    padding: 0;
  }

  .eevery-input {
    min-width: unset;
  }

  .mat-tab-labels {
    margin-left: 30px;
  }

  .languageselector {
    min-width: calc(75% + 32px) !important;
  }

  .display_none_on_mobile {
    display: none;
  }
}

@media only screen and (max-width: 599px) {
  .mat-expansion-panel-body {
    padding: 0 2% 16px !important;
  }

  .mat-dialog-container {
    max-width: 95vw !important;
    width: 95vw !important;
    padding: 16px !important;
  }

  .cdk-overlay-pane {
    width: 95% !important;
    max-width: 95% !important;
    transform: none !important;
  }

  .panel {
    width: 95vw !important;
    max-width: unset !important;
    margin: 0 0 0 -5%;
  }

  .mat-dialog-container {
    max-height: 80vh !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }

  .help_checkbox .mat-checkbox-layout .mat-checkbox-label {
    line-height: 24px;
  }
}

.eevery-scroll-vertical {
  scrollbar-color: $oxygen-blue $oxygen-transparent;
  scrollbar-width: thin;

  & .mat-dialog-container::-webkit-scrollbar {
    width: 0.5em;
  }

  .mat-dialog-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(0, 0, 0, 0.3,);
  }

  .mat-dialog-container::-webkit-scrollbar-thumb {
    background-color: $oxygen-blue;
    border-radius: 2px;
  }
}

.eevery-scroll-vertical::-webkit-scrollbar {
  width: 0.5em;
}

.eevery-scroll-vertical::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(0, 0, 0, 0.3,);
}

.eevery-scroll-vertical::-webkit-scrollbar-thumb {
  background-color: $oxygen-blue;
  border-radius: 2px;
}

.eevery-scroll-dashboard {
  scrollbar-color: $oxygen-blue $oxygen-transparent;
  scrollbar-width: thin;
}

.eevery-scroll-dashboard::-webkit-scrollbar {
  width: 0.5em;
}

.eevery-scroll-dashboard::-webkit-scrollbar-thumb {
  background-color: $oxygen-blue;
  border-radius: 5px;
}

.mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
  margin-right: 0 !important;
}

.view_space {
  width: calc(100vw - 18px);
  height: calc(100vh - 18px);
  margin: 9px;
}

.dotdotdot {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.dots_hover {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  position: relative;
}

.dots_hover:hover {
  min-width: max-content;
  overflow: visible;
  margin-top: 14px;

  & .show_on_hover {
    overflow: visible;
    white-space: unset;
    position: absolute;
    background: #FFFFFF;
    z-index: 10;
    border-radius: 3px;
    padding: 1px 5px 1px 0;
  }
}

.sidenav > .mat-drawer-inner-container {
  overflow-y: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background: none;
  & :hover {
    background: none;
  }
}

/* Firefox */
@supports ( -moz-appearance:none ) {
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.mat-button-toggle-label-content {
  line-height: 30px !important;
}
